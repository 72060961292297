import React, {useEffect, useState} from 'react';
import './styles.scss';
import {PrivateHeader} from '../../components';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import form, {
  getFormUiSuccess,
  submitFormRequest,
} from '../../redux/slicers/form';
import {CustomDispatch} from '../../helpers';
import {removeFormData} from '../../redux/slicers/offlineData';
import {ClipLoader} from 'react-spinners';

const PrivateSharedLayout = ({children}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    // Dispatch the reset action on URL change
    dispatch(getFormUiSuccess(''));
  }, [location, dispatch]);
  const [submitForm, submitFormLoader] = CustomDispatch(submitFormRequest);

  const {isOnline, formsData} = useSelector(state => state.offline);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const submitOfflineData = async () => {
      if (isOnline && formsData && !submitFormLoader) {
        setIsLoading(true); // Set loading state to true
        // Only run if not already submitting
        const formKeys = Object.keys(formsData);

        for (const key of formKeys) {
          const formData = formsData[key];
          console.log(key, formsData, 'submitting form data for key');
          try {
            await submitForm({
              payload: formData,
              pathParams: key,
              logic(res) {
                console.log(key, res, 'submitting form data for key');
                dispatch(removeFormData(key)); // Remove form data after successful submission
              },
              queryParams: {
                notSaveLocal: true,
              },
            });
          } catch (error) {
            console.error(`Error submitting form data for ${key}:`, error);
            // Handle error here (retry logic, logging, etc.)
          }
        }
        setIsLoading(false); // Set loading state
      }
    };

    // Trigger the submission process when network is restored
    submitOfflineData();
  }, [isOnline]);

  return (
    <section className="private-wrapper">
      {isLoading && (
        <div className="z-50 fixed top-0 left-0 w-full h-full bg-[#D9D9D933] backdrop-blur-sm flex flex-col items-center justify-center gap-3 px-4">
          <ClipLoader color={'#004078'} loading={true} size={150} />
          <p className="text-[32px] font-medium text-[#004078] text-center">
            Submitting offline data, please wait...
          </p>
        </div>
      )}
      <PrivateHeader />
      {children}
    </section>
  );
};

export default PrivateSharedLayout;
