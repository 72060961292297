import {createSlice} from '@reduxjs/toolkit';

const GeneralReducer = createSlice({
  name: 'general',
  initialState: {
    procedures: [],
    technicians: [],
    rnList: [],
    searchResults: [],
    reportJson: {},
    carriers: [],
    totalChlorineValues: [],
    waterSoftenerValues: [],
    mdlValues: [],
    reasonCodes: [],
    saltLevels: [],
    shifts: [],
  },
  reducers: {
    getProceduresRequest() {},
    getProceduresSuccess(state, action) {
      state.procedures = [...action.payload];
    },
    getTechniciansRequest() {},
    getTechniciansSuccess(state, action) {
      state.technicians = [...action.payload];
    },
    getRNsRequest() {},
    getRNsSuccess(state, action) {
      state.rnList = [...action.payload];
    },
    searchReportRequest() {},
    searchReportSuccess(state, action) {
      state.searchResults = [...action.payload];
    },
    getPDfJsonRequest() {},
    getPDfJsonSuccess(state, action) {
      state.reportJson = {...action.payload};
    },
    getCarriersRequest() {},
    getCarriersSuccess(state, action) {
      state.carriers = [...action.payload];
    },
    getTotalChlorineValuesRequest() {},
    getTotalChlorineValuesSuccess(state, action) {
      state.totalChlorineValues = [...action.payload];
    },
    getWaterSoftenerValuesRequest() {},
    getWaterSoftenerValuesSuccess(state, action) {
      state.waterSoftenerValues = [...action.payload];
    },
    getMdlValuesRequest() {},
    getMdlValuesSuccess(state, action) {
      state.mdlValues = [...action.payload];
    },
    getReasonCodesRequest() {},
    getReasonCodesSuccess(state, action) {
      state.reasonCodes = [...action.payload];
    },
    getSaltLevelsRequest() {},
    getSaltLevelsSuccess(state, action) {
      state.saltLevels = [...action.payload];
    },
    getShiftsRequest() {},
    getShiftsSuccess(state, action) {
      state.shifts = action.payload;
    },
  },
});

export const {
  getProceduresRequest,
  getProceduresSuccess,
  getTechniciansRequest,
  getTechniciansSuccess,
  searchReportRequest,
  searchReportSuccess,
  getPDfJsonRequest,
  getPDfJsonSuccess,
  getRNsRequest,
  getRNsSuccess,
  getCarriersRequest,
  getCarriersSuccess,
  getTotalChlorineValuesRequest,
  getTotalChlorineValuesSuccess,
  getWaterSoftenerValuesRequest,
  getWaterSoftenerValuesSuccess,
  getMdlValuesRequest,
  getMdlValuesSuccess,
  getReasonCodesRequest,
  getReasonCodesSuccess,
  getSaltLevelsRequest,
  getSaltLevelsSuccess,
  getShiftsRequest,
  getShiftsSuccess,
} = GeneralReducer.actions;

export default GeneralReducer.reducer;
