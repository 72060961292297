import {Button, Col, Form, Input, Modal, Row, Select} from 'antd';
import React, {useEffect} from 'react';
import './styles.scss';
import {getUserDataRequest} from '../../../redux/slicers/user';
import {useSelector} from 'react-redux';
import {CustomDispatch} from '../../../helpers';
import {editMembersRequest} from '../../../redux/slicers/members';
import {ClipLoader} from 'react-spinners';
import {inputFieldRule, toastAlert, toTitleCase} from '../../../utils';
import {getCarriersRequest} from '../../../redux/slicers/general';

const EditProfileModal = ({open, handleClose}) => {
  //CONST VALS
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: 'Please Enter a valid email.',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const [form] = Form.useForm();

  //REDUX DATA
  const {userData, data} = useSelector(state => state.user);
  const {carriers} = useSelector(state => state.general);

  //CUSTOM DISPATCH
  const [getUserData, isLoading] = CustomDispatch(getUserDataRequest);
  const [editMember, editMemberLoader] = CustomDispatch(editMembersRequest);
  const [getCarriers, carriersLoader] = CustomDispatch(getCarriersRequest);

  //HANDLERS
  const handleFinish = vals => {
    editMember({
      payload: {...vals, id: data?.id},
      pathParams: data?.id,
      logic: res => {
        toastAlert('Profile updated successfully');
        handleClose();
        getUserData();
      },
    });
  };

  //HOOKS
  useEffect(() => {
    if (open) {
      getUserData({
        pathParams: data?.id,
      });
    }
  }, [open]);

  useEffect(() => {
    if (!isLoading && open && Object.keys(userData).length > 0) {
      form.setFieldsValue({
        first_name: userData?.firstName,
        last_name: userData?.lastName,
        email: userData?.email,
        phone: userData?.phone,
        username: userData?.userName,
        password: userData?.password,
        email_to_sms_gateway: userData?.carrier,
      });
    }
  }, [userData, isLoading]);

  useEffect(() => {
    if (open) {
      getCarriers();
    }
  }, [open]);

  return (
    <Modal
      width={750}
      open={open}
      closeIcon={false}
      destroyOnClose
      footer={null}
      centered
      className="profile-modal">
      <p className="font-h4 mb-5">Profile Settings</p>
      {isLoading ? (
        <div className="flex items-center justify-center h-[300px]">
          <ClipLoader color="#123abc" size={30} />
        </div>
      ) : (
        <Form
          form={form}
          className="px-2"
          validateMessages={validateMessages}
          onFinish={handleFinish}>
          <Row gutter={[50, 10]}>
            <Col span={24}>
              <p className="font-subtext text-[14px]">Personal Information</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">First Name</p>
              <Form.Item name={'first_name'}>
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">Last Name</p>
              <Form.Item name={'last_name'}>
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>{' '}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">Email</p>
              <Form.Item
                name={'email'}
                rules={[
                  {
                    type: 'email',
                  },
                ]}>
                <Input type="email" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">Mobile Carrier</p>
              <Form.Item
                rules={inputFieldRule({
                  name: 'Carrier',
                  isRequired: true,
                  isWhiteSpace: false,
                })}
                name={'email_to_sms_gateway'}>
                <Select
                  options={carriers?.map(carrier => ({
                    label: toTitleCase(carrier.carrier_name),
                    value: carrier.id,
                  }))}
                  loading={carriersLoader}
                  placeholder="Select Carrier"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">Phone</p>
              <Form.Item name={'phone'}>
                <Input placeholder="Phone" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[50, 10]} className="mt-10">
            <Col span={24}>
              <p className="font-subtext text-[14px]">Account Information</p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">User Name</p>
              <Form.Item name={'username'}>
                <Input placeholder="User Name" />
              </Form.Item>
            </Col>{' '}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <p className="font-h4 mb-2">PIN</p>
              <Form.Item name={'password'}>
                <Input.Password placeholder="PIN" />
              </Form.Item>
            </Col>
          </Row>

          <div className="flex items-center justify-end mt-10 gap-3 sm:flex-nowrap flex-wrap">
            <Button
              onClick={handleClose}
              className="cancel-btn sm:w-[100px] w-full text-textColor-100 uppercase text-[10px]  disabled:bg-transparent bg-transparent rounded-md  hover:bg-transparent ">
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="disabled:bg-transparent sm:w-[100px] w-full uppercase text-[10px] bg-blue-300 text-white-100  rounded-md  hover:bg-opacity-70">
              Save
            </Button>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default EditProfileModal;
