import {createSlice} from '@reduxjs/toolkit';

const FormReducer = createSlice({
  name: 'form',
  initialState: {
    formPayload: {},
    formUiJson: {},
    // shifts: [],
  },
  reducers: {
    getPayloadRequest() {},
    getPayloadSuccess(state, action) {
      state.formPayload = action.payload;
    },
    submitFormRequest() {},
    submitFormSuccess(state, action) {
      //   state.technicians = [...action.payload];
    },
    getFormUiRequest() {},
    getFormUiSuccess(state, action) {
      console.log(action.payload, 'formSchema');
      state.formUiJson = action.payload;
    },
    // getShiftsRequest() {},
    // getShiftsSuccess(state, action) {
    //   state.shifts = action.payload;
    // },
  },
});

export const {
  getPayloadRequest,
  getPayloadSuccess,
  submitFormRequest,
  submitFormSuccess,
  getFormUiRequest,
  getFormUiSuccess,
  // getShiftsRequest,
  // getShiftsSuccess,
} = FormReducer.actions;

export default FormReducer.reducer;
