import {take, put, call, fork} from 'redux-saga/effects';
import {ALERT_TYPES} from '../../constants';
import {
  callRequest,
  GET_CARRIERS,
  GET_MDL_RESULT,
  GET_PDF_JSON,
  GET_PROCEDURES,
  GET_REASON_CODE,
  GET_RN,
  GET_SALT_LEVEL,
  GET_SHIFTS,
  GET_TECHNICIANS,
  GET_TOTAL_CHLORINE,
  GET_WATER_SOFTENER,
  SEARCH_REPORT,
} from '../../config/web-service';
import {getCurrentCompanyId, toastAlert} from '../../utils';
import {manipulateUserData} from '../../data-manipulator/user';
import {
  getCarriersRequest,
  getCarriersSuccess,
  getMdlValuesRequest,
  getMdlValuesSuccess,
  getPDfJsonRequest,
  getPDfJsonSuccess,
  getProceduresRequest,
  getProceduresSuccess,
  getReasonCodesRequest,
  getReasonCodesSuccess,
  getRNsRequest,
  getRNsSuccess,
  getSaltLevelsRequest,
  getSaltLevelsSuccess,
  getShiftsRequest,
  getShiftsSuccess,
  getTechniciansRequest,
  getTechniciansSuccess,
  getTotalChlorineValuesRequest,
  getTotalChlorineValuesSuccess,
  getWaterSoftenerValuesRequest,
  getWaterSoftenerValuesSuccess,
  searchReportRequest,
  searchReportSuccess,
} from '../slicers/general';
import {
  manipulateProceduresList,
  parseJson,
  manipulateSearchResultsList,
  manipulateTechniciansList,
  manipulateFormValuesList,
  manipulateShiftsList,
} from '../../data-manipulator/general';

// GET PROCEDURES
function* getProcedures() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getProceduresRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_PROCEDURES,
        pathParams: getCurrentCompanyId(),
      });
      console.log(response);
      if (response.response_code === '200') {
        yield put(
          getProceduresSuccess(
            manipulateProceduresList(response.response_data),
          ),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET TECHNICIANS
function* getTechnicians() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getTechniciansRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_TECHNICIANS,
      });
      console.log(response);
      if (response.response_code === '200') {
        yield put(
          getTechniciansSuccess(
            manipulateTechniciansList(response.response_data),
          ),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
// GET RN
function* getRN() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getRNsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_RN,
      });
      console.log(response);
      if (response.response_code === '200') {
        yield put(
          getRNsSuccess(manipulateTechniciansList(response.response_data)),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// SEARCH REPORT
function* searchReport() {
  while (true) {
    const {
      payload: {pathParams, queryParams, responseCallback},
    } = yield take(searchReportRequest.type);
    try {
      const response = yield call(callRequest, {
        url: SEARCH_REPORT,
        pathParams: pathParams,
        payload: queryParams,
      });
      if (response.response_code === '200') {
        yield put(
          searchReportSuccess(
            manipulateSearchResultsList(response.response_data),
          ),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET PDF JSON
function* getPdfJson() {
  while (true) {
    const {
      payload: {pathParams, payload, responseCallback},
    } = yield take(getPDfJsonRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_PDF_JSON,
        payload,
        pathParams: pathParams,
      });
      if (response.response_code === '200') {
        console.log(response, 'json');
        yield put(getPDfJsonSuccess(response.response_data));
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET CARRIERS
function* getCarriers() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getCarriersRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_CARRIERS,
      });
      if (response.response_code === '200') {
        yield put(getCarriersSuccess(response.response_data ?? []));
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET TOTAL CHLORINE VALUES
function* getTotalChlorineValues() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getTotalChlorineValuesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_TOTAL_CHLORINE,
      });
      console.log(response);
      if (response.response_code === '200') {
        yield put(
          getTotalChlorineValuesSuccess(
            manipulateFormValuesList(response.response_data),
          ),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET WATER SOFTENER VALUES
function* getWaterSoftenerValues() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getWaterSoftenerValuesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_WATER_SOFTENER,
      });
      console.log(response);
      if (response.response_code === '200') {
        yield put(
          getWaterSoftenerValuesSuccess(
            manipulateFormValuesList(response.response_data),
          ),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET MDL RESULTS VALUES
function* getMdlResultValues() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getMdlValuesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_MDL_RESULT,
      });
      console.log(response);
      if (response.response_code === '200') {
        yield put(
          getMdlValuesSuccess(manipulateFormValuesList(response.response_data)),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
// GET REASON CODES
function* getReasonCodes() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getReasonCodesRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_REASON_CODE,
      });
      console.log(response);
      if (response.response_code === '200') {
        yield put(
          getReasonCodesSuccess(
            response.response_data?.map(item => ({
              label: item?.value,
              value: item?.name,
            })),
          ),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
// GET SALT LEVELS
function* getSaltLevels() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getSaltLevelsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_SALT_LEVEL,
      });
      console.log(response);
      if (response.response_code === '200') {
        yield put(
          getSaltLevelsSuccess(
            manipulateFormValuesList(response.response_data),
          ),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

// GET ROLES
function* getShifts() {
  while (true) {
    const {
      payload: {responseCallback},
    } = yield take(getShiftsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_SHIFTS,
      });
      if (response.response_code === '200') {
        responseCallback?.(true, response);
        yield put(
          getShiftsSuccess(manipulateShiftsList(response.response_data)),
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}
export default function* root() {
  yield fork(getProcedures);
  yield fork(getTechnicians);
  yield fork(getRN);
  yield fork(searchReport);
  yield fork(getPdfJson);
  yield fork(getCarriers);
  yield fork(getTotalChlorineValues);
  yield fork(getWaterSoftenerValues);
  yield fork(getMdlResultValues);
  yield fork(getReasonCodes);
  yield fork(getSaltLevels);
  yield fork(getShifts);
}
