import {ReactComponent as EberLogo} from '../assets/icons/EBER Logo_2.svg';
import {ReactComponent as AlertIcon} from '../assets/icons/Alert_n.svg';
import {ReactComponent as SearchIcon} from '../assets/icons/Search_n.svg';
import {ReactComponent as ExitIcon} from '../assets/icons/Exit_n.svg';
import {ReactComponent as ProcedureIcon} from '../assets/icons/Procedures_n.svg';
import {ReactComponent as ClockIcon} from '../assets/icons/Clock_n.svg';
import {ReactComponent as LeftChevron} from '../assets/icons/left_chev_n.svg';
import {ReactComponent as PrintIcon} from '../assets/icons/Print_n.svg';
import {ReactComponent as ExportIcon} from '../assets/icons/Export_n.svg';
import {ReactComponent as Checked} from '../assets/icons/Checked_Stat.svg';
import {ReactComponent as CheckedDisabled} from '../assets/icons/Checked_Disabled.svg';
import {ReactComponent as CloseIcon} from '../assets/icons/close_n.svg';
import {ReactComponent as PdfIcon} from '../assets/icons/PDF_n.svg';
import {ReactComponent as UserIcon} from '../assets/icons/User_n.svg';
import {ReactComponent as ProfileIcon} from '../assets/icons/Profile_n.svg';
import {ReactComponent as SettingsIcon} from '../assets/icons/Settings_n.svg';
import {ReactComponent as NotificationIcon} from '../assets/icons/Notification_n.svg';
import {ReactComponent as MembersIcon} from '../assets/icons/Users_n.svg';
import {ReactComponent as EditIcon} from '../assets/icons/edit_n.svg';
import {ReactComponent as DeleteIcon} from '../assets/icons/Trash_n.svg';
import {ReactComponent as DeleteIconDisabled} from '../assets/icons/Trash_mo.svg';
import {ReactComponent as InfoIcon} from '../assets/icons/info_icon.svg';
export default {
  EberLogo,
  AlertIcon,
  SearchIcon,
  ExitIcon,
  ProcedureIcon,
  ClockIcon,
  LeftChevron,
  PrintIcon,
  ExportIcon,
  Checked,
  CheckedDisabled,
  CloseIcon,
  PdfIcon,
  UserIcon,
  ProfileIcon,
  SettingsIcon,
  NotificationIcon,
  MembersIcon,
  EditIcon,
  DeleteIcon,
  DeleteIconDisabled,
  InfoIcon,
};
