import {take, put, call, fork, takeLatest, takeEvery} from 'redux-saga/effects';
import {ALERT_TYPES} from '../../constants';
import {
  callRequest,
  GET_FORM_PAYLOAD,
  GET_FORM_UI,
  GET_SHIFTS,
  GET_TECHNICIANS,
  SUBMIT_FORM,
} from '../../config/web-service';
import {toastAlert} from '../../utils';
import {
  getFormUiRequest,
  getFormUiSuccess,
  getPayloadRequest,
  getPayloadSuccess,
  getShiftsRequest,
  getShiftsSuccess,
  submitFormRequest,
  submitFormSuccess,
} from '../slicers/form';
import {manipulateShiftsList} from '../../data-manipulator/general';
import {saveFormDataLocally, saveFormUiLocally} from '../slicers/offlineData';

// GET PROCEDURES
function* getPayload() {
  while (true) {
    const {
      payload: {responseCallback, pathParams},
    } = yield take(getPayloadRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_FORM_PAYLOAD,
        pathParams: pathParams,
      });
      console.log(response, 'cjeck');
      if (response.message === 'Api not found, Please try again later') {
        window.location.href = '/';
      }
      if (
        response.response_code === '200' ||
        response.response_code === '205'
      ) {
        yield put(getPayloadSuccess(response.response_data));
        responseCallback?.(
          response.response_code === '200' || response.response_code === '205'
            ? true
            : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

//SUBMIT FORM

function* submitForm(action) {
  const {
    payload: {responseCallback, pathParams, queryParams, payload},
  } = action;

  if (!queryParams?.notSaveLocal) {
    yield put(saveFormDataLocally({key: pathParams, data: payload}));
  }

  try {
    const response = yield call(callRequest, {
      url: SUBMIT_FORM,
      payload: payload,
      pathParams: pathParams,
    });

    if (response.response_code === '200' || response.response_code === '300') {
      responseCallback?.(true, response);
      yield put(submitFormSuccess(response.response_data));
    } else {
      responseCallback?.(false, response);

      // response.message && toastAlert(response.message, ALERT_TYPES.ERROR);
    }
  } catch (err) {
    responseCallback?.(false, err);
  }
}

// GET TECHNICIANS
function* getFormUi() {
  while (true) {
    const {
      payload: {responseCallback, pathParams},
    } = yield take(getFormUiRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_FORM_UI,
        pathParams: pathParams,
      });
      if (response.response_code === '200') {
        console.log('testing', response);
        yield put(getFormUiSuccess(response.response_data));
        yield put(
          saveFormUiLocally({key: pathParams, data: response.response_data}),
        );
        responseCallback?.(
          response.response_code === '200' ? true : false,
          response,
        );
      } else {
        responseCallback?.(false, response);
        response.response_description &&
          toastAlert(response.response_description, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
    }
  }
}

export default function* root() {
  yield fork(getPayload);
  yield fork(getFormUi);
  yield takeEvery(submitFormRequest.type, submitForm);
  // yield fork(getShifts);
}
