import React, {useEffect} from 'react';
import {Grid} from '@mui/material';
import {Loader, ProcedureCards} from '../../../components';
import {FORM_ROUTE, PROCEDURES_LIST} from '../../../constants';
import './styles.scss';
import {CustomDispatch} from '../../../helpers';
import {getProceduresRequest} from '../../../redux/slicers/general';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {replaceValInString} from '../../../utils';
import {getPayloadSuccess} from '../../../redux/slicers/form';

const Dashboard = () => {
  //CONST VALS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //REDUX DATA
  const {procedures} = useSelector(state => state.general);
  const {isOnline} = useSelector(state => state.offline);
  const {data} = useSelector(state => state.user);

  //CUSTOM DISPATCH
  const [getProcedures, proceduresLoader] =
    CustomDispatch(getProceduresRequest);

  //HANDLERS
  const handleCardClick = id => {
    navigate(replaceValInString(FORM_ROUTE, {':id': id}));
    dispatch(getPayloadSuccess({}));
  };

  console.log(data, 'data');
  //HOOKS
  useEffect(() => {
    if (data?.id) getProcedures();
  }, [data]);

  return (
    <section className="dashboard mt-[69px] ">
      <div className="h-[50px] bg-grey-100 border-b-[1px] border-solid border-grey-200 "></div>
      {proceduresLoader ? (
        <Loader height="80vh" />
      ) : (
        <div className="dashboard-content px-[20px] md:px-[40px] py-[20px]">
          <p className="font-arial font-medium text-xl text-textColor-100 mb-[30px]">
            Available Procedures
          </p>
          <Grid
            container
            columnSpacing={{xs: '40px', xl: '90px'}}
            rowSpacing={{xs: '40px', xl: '60px'}}>
            {procedures?.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <ProcedureCards
                  title={item?.title}
                  description={item?.description}
                  onClick={() => handleCardClick(item?.id)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </section>
  );
};

export default Dashboard;
