import {Button, Form, Input} from 'antd';
import React from 'react';
import {Link} from 'react-router-dom';
import {inputFieldRule} from '../../utils';
import {useDispatch, useSelector} from 'react-redux';
import {loginSuccess, loginRequest} from '../../redux/slicers/user';
import {CustomDispatch} from '../../helpers';

const Login = () => {
  //CONST VALS
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  //REDUX DATA
  const {isOnline} = useSelector(state => state.offline);

  //CUSTOM DISPATCH
  const [login, loginLoader] = CustomDispatch(loginRequest);

  //HANDLERS
  const handleFormFinish = vals => {
    console.log(vals);
    if (!isOnline) {
      dispatch(loginSuccess());
      return;
    }
    login({payload: vals});
  };

  return (
    <div className="min-h-screen bg-[#EBF1F4] flex items-center justify-center">
      <div className="w-[540px] min-h-[400px] bg-gradient-to-b px-[30px] py-[20px] flex flex-col items-start justify-start shadow-xl">
        <p className="font-h1 text-[18px]">Welcome to ...</p>
        <div className="flex flex-col items-center justify-start w-full">
          <p className="font-h1 text-[18px] mt-[15px] mb-[25px] text-center">
            {process.env.REACT_APP_TENANT_NAME}
          </p>
          <Form form={form} className="w-[195px] " onFinish={handleFormFinish}>
            <Form.Item
              name={'username'}
              rules={inputFieldRule({
                name: 'Username',
                isRequired: true,
              })}>
              <Input
                className="rounded-none text-[12px]"
                placeholder="User Name"
              />
            </Form.Item>
            <Form.Item
              name={'password'}
              rules={inputFieldRule({
                name: 'PIN',
                isRequired: true,
              })}>
              <Input.Password
                className="rounded-none text-[12px]"
                placeholder="PIN"
              />
            </Form.Item>
            <Button
              loading={loginLoader}
              htmlType="submit"
              className="w-full disabled:bg-transparent bg-blue-300 text-white-100 text-[12px] rounded-none  hover:bg-opacity-70">
              SIGN IN
            </Button>
          </Form>
          <Link className="text-[10px] font-medium text-[#004078] mt-[15px]">
            Forgot PIN?
          </Link>
          <div className="flex items-center justify-center gap-1 mt-[25px] md:flex-nowrap flex-wrap">
            <p className="font-paragraph-sm text-[12px]">
              If you need access, you can submit a request to the system
              administrator for assistance.
            </p>
            <div className="flex flex-nowrap text-nowrap text-[14px] items-center justify-center border-[2px] border-blue-300 text-blue-300 font-medium rounded-md px-[15px] py-[10px] cursor-pointer select-none">
              REQUEST ACCOUNT
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
